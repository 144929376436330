import React from "react"
import Layout from "./../components/layout.component"
import BlockContent from "@sanity/block-content-to-react"
import Form from "./../components/form.component"

const JobTemplate = props => {
  const { job } = props.pageContext
  return (
    <Layout>
      <section className="job-overview">
        <div className="job-overview__content container">
          <h2 className="heading__secondary__alternative job-overview">
            {job.title}{" "}
            <span className="job-overview__location">( {job.location} )</span>
          </h2>
          <BlockContent
            className="paragraph job-overview__content"
            renderContainerOnSingleChild={true}
            blocks={job._rawLongDescription}
          />
        </div>
      </section>
      <section className="contact" id="contact">
        <div className="contact__content container">
          <h2 className="heading__secondary__alternative contact__title">
            Contact
          </h2>
          <Form />
        </div>
      </section>
    </Layout>
  )
}

export default JobTemplate
